var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
function _extends() {
  exports = _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  }, exports.__esModule = true, exports["default"] = exports;
  return _extends.apply(this || _global, arguments);
}
exports = _extends, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;